const trapFocusHandlers = {};

/**
 * Removes focus trap event listeners and optionally focuses an element.
 * @param {Element} [elementToFocus=null] - Element to focus when trap is removed.
 */
function removeTrapFocus(elementToFocus = null) {
  document.removeEventListener('focusin', trapFocusHandlers.focusin);
  document.removeEventListener('focusout', trapFocusHandlers.focusout);
  document.removeEventListener('keydown', trapFocusHandlers.keydown);

  if (elementToFocus) elementToFocus.focus();
}

/**
 * Traps focus within a container, e.g. modal or side drawer.
 * @param {Element} container - Container element to trap focus within.
 * @param {Element} [elementToFocus=container] - Initial element to focus when trap is applied.
 */
function trapFocus(container, elementToFocus = container) {
  const focusableEls = Array.from(
    container.querySelectorAll('summary, a[href], area[href], button:not([disabled]), input:not([type=hidden]):not([disabled]), select:not([disabled]), textarea:not([disabled]), object, iframe, audio[controls], video[controls], [tabindex]:not([tabindex^="-"])')
  );

  const firstEl = focusableEls[0];
  const lastEl = focusableEls[focusableEls.length - 1];

  removeTrapFocus();

  trapFocusHandlers.focusin = (evt) => {
    if (evt.target !== container && evt.target !== lastEl && evt.target !== firstEl) return;
    document.addEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.focusout = () => {
    document.removeEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.keydown = (evt) => {
    if (evt.code !== 'Tab') return;

    // If tab pressed on last focusable element, focus the first element.
    if (evt.target === lastEl && !evt.shiftKey) {
      evt.preventDefault();
      firstEl.focus();
    }

    //  If shift + tab pressed on the first focusable element, focus the last element.
    if ((evt.target === container || evt.target === firstEl) && evt.shiftKey) {
      evt.preventDefault();
      lastEl.focus();
    }
  };

  document.addEventListener('focusout', trapFocusHandlers.focusout);
  document.addEventListener('focusin', trapFocusHandlers.focusin);

  (elementToFocus || container).focus();
}


class MainMenu extends HTMLElement {
  constructor() {
    super();
    this.mainDisclosure = this.querySelector('.main-menu__disclosure');
    this.mainToggle = this.querySelector('.main-menu__toggle');
    this.firstLevelMenuLinks = this.querySelectorAll('.js-nav-hover');
    this.firstLevelSingleLinks = this.querySelectorAll('.main-nav__item--primary:not(.main-nav__item-content)');
    this.nav = this.querySelector('.main-nav');
    this.overlay = document.querySelector('.js-overlay');
    this.searchIcon = document.querySelector('.header__icons .js-show-search');
    this.sidebarLinks = this.querySelectorAll('.js-sidebar-hover');
    this.elementsWhichCloseMenus = document.querySelectorAll('.js-closes-menu');
    this.childNavLinks = this.querySelectorAll('.child-nav__li');

    this.childNavOpen = false;
    this.overlayOpen = false;

    this.addListeners();
    this.init();
  }

  disconnectedCallback() {
    window.removeEventListener('focusin', this.focusOutHandler);
    window.removeEventListener('on:breakpoint-change', this.breakpointChangeHandler);
  }

  addListeners() {
    this.focusOutHandler = this.focusOutHandler || this.handleFocusOut.bind(this);
    this.breakpointChangeHandler = this.breakpointChangeHandler || this.init.bind(this);

    if (this.mainDisclosure) {
      this.mainDisclosure.addEventListener('transitionend', MainMenu.handleTransition.bind(this));
    }
    if (this.mainToggle) {
      this.mainToggle.addEventListener('click', this.handleMainMenuToggle.bind(this));
    }
    if (this.nav) {
      this.nav.addEventListener('click', MainMenu.handleNavClick.bind(this));
    }
    window.addEventListener('focusin', this.focusOutHandler);
    window.addEventListener('on:breakpoint-change', this.breakpointChangeHandler);

    if (!theme.mediaMatches.md && this.searchIcon) {
      this.searchIcon.addEventListener('click', this.closeMainDisclosure.bind(this));
    }
  }

  /**
   * Sets 'open' state of the main disclosure element.
   * @param {?object} evt - Event object.
   */
  init(evt) {
    if (!evt) {
      if (this.mainDisclosure) {
        this.mainDisclosure.open = theme.mediaMatches.md;
      }
    } else if (!theme.mediaMatches.md && !this.childNavOpen) {
      if (this.mainDisclosure) {
        this.close(this.mainDisclosure);
      }

      if (this.overlayOpen) this.toggleOverlay(false);
    } else {
      // If there's another menu open, close it.
      if (this.nav) {
        const activeDisclosure = this.nav.querySelector('details.is-open');
        if (activeDisclosure) {
          this.close(activeDisclosure);
        } else {
          if (this.mainDisclosure) {
            MainMenu.open(this.mainDisclosure, false);
          }
        }
      }

      if (!this.childNavOpen) {
        if (this.overlayOpen) this.toggleOverlay(false);
      }
    }

    // Close the submenus (they're open for no-js)
    this.querySelectorAll('.child-nav--dropdown details[open]').forEach((childToggle) => {
      childToggle.removeAttribute('open');
    });
  
    if(theme.mediaMatches.md) {
      this.querySelectorAll('.child-nav details').forEach((childToggle) => {
        childToggle.setAttribute('open', true);
      });
    }

    if (theme.device.hasHover) {
      // Add event handler (so the bound event listener can be removed)
      this.mouseEnterMenuLinkHandler = this.mouseEnterMenuLinkHandler
        || this.handleMouseEnterMenuLink.bind(this);
      this.mouseLeaveMenuLinkHandler = this.mouseLeaveMenuLinkHandler
        || this.handleMouseLeaveMenuLink.bind(this);
      this.mouseEnterSingleLinkHandler = this.mouseEnterSingleLinkHandler
        || this.handleMouseEnterSingleLink.bind(this);
      this.mouseLeaveSingleLinkHandler = this.mouseLeaveSingleLinkHandler
        || this.handleMouseLeaveSingleLink.bind(this);
      this.mouseEnterMenuCloserHandler = this.mouseEnterMenuCloserHandler
        || this.handleClose.bind(this);
      this.mouseEnterChildNavHandler = this.mouseEnterChildNavHandler
        || this.handleMouseEnterChildNav.bind(this);
      this.mouseLeaveChildNavHandler = this.mouseLeaveChildNavHandler
        || this.handleMouseLeaveChildNav.bind(this);

      // Bind listening events for mouse enter/leave a main menu link
      if (!this.mouseOverListening && theme.mediaMatches.md) {
        this.firstLevelMenuLinks.forEach((menuLink) => {
          menuLink.addEventListener('mouseenter', this.mouseEnterMenuLinkHandler);
          menuLink.addEventListener('mouseleave', this.mouseLeaveMenuLinkHandler);
        });
        this.firstLevelSingleLinks.forEach((singleLink) => {
          singleLink.addEventListener('mouseenter', this.mouseEnterSingleLinkHandler);
          singleLink.addEventListener('mouseleave', this.mouseLeaveSingleLinkHandler);
        });
        this.elementsWhichCloseMenus.forEach((elem) => {
          elem.addEventListener('mouseenter', this.mouseEnterMenuCloserHandler);
        });
        this.mouseOverListening = true;
      } else if (this.mouseOverListening && !theme.mediaMatches.md) {
        this.firstLevelMenuLinks.forEach((menuLink) => {
          menuLink.removeEventListener('mouseenter', this.mouseEnterMenuLinkHandler);
          menuLink.removeEventListener('mouseleave', this.mouseLeaveMenuLinkHandler);
        });
        this.firstLevelSingleLinks.forEach((singleLink) => {
          singleLink.removeEventListener('mouseenter', this.mouseEnterSingleLinkHandler);
          singleLink.removeEventListener('mouseleave', this.mouseLeaveSingleLinkHandler);
        });
        this.elementsWhichCloseMenus.forEach((elem) => {
          elem.removeEventListener('mouseenter', this.mouseEnterMenuCloserHandler);
        });
        this.mouseOverListening = false;
      }

      if (this.sidebarLinks) {
        if (!this.mouseOverSidebarListening && theme.mediaMatches.md) {
          this.sidebarLinks.forEach((sidebarLink) => {
            sidebarLink.addEventListener('mouseenter', MainMenu.handleSidenavMenuToggle);
          });
          this.mouseOverSidebarListening = true;
        } else if (this.mouseOverSidebarListening && !theme.mediaMatches.md) {
          this.sidebarLinks.forEach((sidebarLink) => {
            sidebarLink.removeEventListener('mouseenter', MainMenu.handleSidenavMenuToggle);
          });
          this.mouseOverSidebarListening = false;
        }
      }

      if (this.childNavLinks) {
        if (!this.mouseOverChildNavListening && theme.mediaMatches.md) {
          this.childNavLinks.forEach((childNavLink) => {
            childNavLink.addEventListener('mouseenter', this.mouseEnterChildNavHandler);
            childNavLink.addEventListener('mouseleave', this.mouseLeaveChildNavHandler);
          });
          this.mouseOverChildNavListening = true;
        } else if (this.mouseOverChildNavListening && !theme.mediaMatches.md) {
          this.childNavLinks.forEach((childNavLink) => {
            childNavLink.removeEventListener('mouseenter', this.mouseEnterChildNavHandler);
            childNavLink.removeEventListener('mouseleave', this.mouseLeaveChildNavHandler);
          });
          this.mouseOverChildNavListening = false;
        }

      }
    }
  }

  /**
   * Close the menu if the nav loses focus
   * @param {object} evt - Event object.
   */
  handleFocusOut(evt) {
    if (!this.contains(evt.target) && this.overlayOpen) this.handleClose();
  }

  /**
   * Updates the visible sidebar item
   * @param {object} evt - Event object.
   * @param {Element} [summaryElem] - The summary element to open.
   */
  static handleSidenavMenuToggle(evt, summaryElem = evt.target) {
    const container = summaryElem.closest('.child-nav');
    const lastSidenavElem = container.querySelector('.is-visible');
    if (lastSidenavElem) {
      lastSidenavElem.classList.remove('is-visible');
    }
    summaryElem.classList.add('is-visible');

    // Maintain a CSS variable which records the height of the current sidebar links
    const menu = summaryElem.closest('nav-menu');
    if (menu) {
      const openDisclosure = menu.querySelector('.disclosure__panel');
      if (openDisclosure) {
        container.style.setProperty(
          '--sidebar-height',
          `${Number.parseInt(openDisclosure.getBoundingClientRect().height, 10)}px`
        );
      }
    }
  }

  /**
   * Handles 'toggle' event on the main disclosure element.
   * @param {object} evt - Event object.
   */
  handleMainMenuToggle(evt) {
    evt.preventDefault();
    this.opener = this.mainToggle;

    if (!this.mainDisclosure) {
      return;
    }

    if (!this.mainDisclosure.open) {
      MainMenu.open(this.mainDisclosure);
    } else {
      this.close(this.mainDisclosure, true);
    }
  }

  /**
   * Handles 'mouseenter' event on the main menu items using a timeout to infer hover intent
   * @param {object} evt - Event object
   */
  handleMouseEnterMenuLink(evt) {
    this.menuLinkTimeout = setTimeout(
      this.openMenuFromMouseEnter.bind(this, evt.target),
      Number.parseInt(this.dataset.menuSensitivity, 10)
    );
  }

  /**
   * Handles 'mouseleave' event on the main menu items - clears the timeout
   */
  handleMouseLeaveMenuLink() {
    if (this.menuLinkTimeout) clearTimeout(this.menuLinkTimeout);
  }

  /**
   * Handles 'mouseenter' event on links with no submenu items using a timeout to infer hover intent
   */
  handleMouseEnterSingleLink() {
    this.singleLinkTimeout = setTimeout(() => {
      this.handleClose();
    }, Number.parseInt(this.dataset.menuSensitivity, 10));
  }

  /**
   * Handles 'mouseleave' event on links with no submenu - clears the timeout
   */
  handleMouseLeaveSingleLink() {
    if (this.singleLinkTimeout) clearTimeout(this.singleLinkTimeout);
  }

  handleMouseEnterChildNav(evt) {
    this.childNavTimeout = setTimeout(
      this.openChildNavFromMouseEnter.bind(this, evt.target),
      Number.parseInt(this.dataset.menuSensitivity, 10)
    );
  }

  handleMouseLeaveChildNav() {
    if (this.childNavTimeout) clearTimeout(this.childNavTimeout);
  }

  openChildNavFromMouseEnter(navElem) {
    if (this.nav) {
      const activeDisclosure = this.nav.querySelector('details.is-open');
      if(!navElem.classList.contains('is-open')) {
        const activeChildNav = activeDisclosure.querySelector('.child-nav__li.is-open');

        if (activeChildNav && activeChildNav !== navElem) {
          this.closeNav(activeDisclosure, activeChildNav);
        }
      }

      this.openNav(activeDisclosure, navElem);
    }
  }

  openNav(activeDisclosure, navElem) {
    navElem.classList.add("is-open");
    let navid = navElem.dataset.navid;
    let childMenu = activeDisclosure.querySelector('.child-menus-'+navid);
    let childPromo = activeDisclosure.querySelector('.child-promos-'+navid);
    if(childMenu) childMenu.classList.remove("hidden");
    if(childPromo) childPromo.classList.remove("hidden");
  }

  closeNav(activeDisclosure, activeChildNav) {
    activeChildNav.classList.remove('is-open');
    let navid = activeChildNav.dataset.navid;
    let childMenu = activeDisclosure.querySelector('.child-menus-'+navid);
    let childPromo = activeDisclosure.querySelector('.child-promos-'+navid);
    if(childMenu) childMenu.classList.add("hidden");
    if(childPromo) childPromo.classList.add("hidden");
  }

  /**
   * Opens the menu being hovered over
   * @param {Element} menuElem - The menu element to open.
   */
  openMenuFromMouseEnter(menuElem) {
    trapFocus(menuElem);

    const disclosure = menuElem.closest('details');
    if (!disclosure.classList.contains('is-open') && this.nav) {
      const activeDisclosure = this.nav.querySelector('details.is-open');

      // If there's another menu open, close it.
      if (activeDisclosure && activeDisclosure !== disclosure) {
        this.close(activeDisclosure);
      } else {
        this.toggleOverlay(!this.overlayOpen);
      }

      MainMenu.open(disclosure);
    }
  }

  /**
   * Handles 'click' event on the nav.
   * @param {object} evt - Event object.
   */
  static handleNavClick(evt) {
    const mainMenuContent = evt.target.closest('.main-menu__content');
    let el = evt.target;

    // Handle sidebar link clicks
    if (theme.mediaMatches.md && el.matches('.js-sidebar-hover') && el.closest('summary')) {
      evt.preventDefault();
      MainMenu.handleSidenavMenuToggle(evt);
    }

    // Don't follow # links
    if (evt.target.href && evt.target.href.endsWith('#')) evt.preventDefault();

    // If we're on a device without hover on a larger screen, open the menu
    if (theme.mediaMatches.md && !theme.device.hasHover) {
      el = evt.target.closest('.js-toggle');
      if (!el) return;
    }

    if (!el.matches('.js-toggle,.js-back')) return;

    const disclosure = el.closest('details');

    if (theme.mediaMatches.md && theme.device.hasHover) {
      disclosure.classList.toggle('is-open');
      return;
    }

    this.opener = el;

    if (el.matches('.js-toggle')) {
      evt.preventDefault();

      if (!theme.mediaMatches.md) {
        mainMenuContent.classList.add('main-menu__content--no-focus');
      }

      if (!disclosure.classList.contains('is-open') && this.nav) {
        this.childNavOpen = true;

        const activeDisclosure = this.nav.querySelector('details.is-open');
        // If there's another menu open, close it.
        if (activeDisclosure && activeDisclosure !== disclosure) {
          this.close(activeDisclosure);
        } else if (theme.mediaMatches.md) {
          this.toggleOverlay(!this.overlayOpen);
        }

        MainMenu.open(disclosure);
      } else {
        this.close(disclosure, true);
        this.childNavOpen = false;
        this.toggleOverlay(false);
      }
    } else if (el.matches('.js-back')) {
      evt.preventDefault();
      this.close(disclosure, true);
      this.childNavOpen = false;

      if (!theme.mediaMatches.md) {
        mainMenuContent.classList.remove('main-menu__content--no-focus');
      }
    }
  }

  /**
   * Handles 'transitionend' event on the nav.
   * @param {object} evt - Event object.
   */
  static handleTransition(evt) {
    const disclosure = evt.target.closest('details');

    if (disclosure.classList.contains('is-closing')) {
      disclosure.classList.remove('is-closing');
      disclosure.open = false;

      removeTrapFocus();
      this.opener = null;
    }
  }

  /**
   * Handles a 'click' event on the overlay and a 'keyup' event on the nav.
   * @param {object} evt - Event object.
   */
  handleClose(evt) {
    if (evt && evt.type === 'keyup' && evt.key !== 'Escape') return;

    const disclosure = (theme.mediaMatches.md && this.nav)
      ? this.nav.querySelector('details.is-open')
      : this.mainDisclosure;

    if (disclosure) {
      this.close(disclosure, true);
      this.toggleOverlay(false);
      this.childNavOpen = false;
    }
  }

  /**
   * Toggles visibility of the background overlay.
   * @param {boolean} show - Show the overlay.
   */
  toggleOverlay(show) {
    this.overlayOpen = show;
    this.overlay.classList.toggle('overlay--nav', show);
    this.overlay.classList.toggle('is-visible', show);

    if (show) {
      // Add event handler (so the bound event listener can be removed).
      this.closeHandler = this.closeHandler || this.handleClose.bind(this);

      // Add event listeners (for while the nav is open).
      this.overlay.addEventListener('click', this.closeHandler);
      this.nav.addEventListener('keyup', this.closeHandler);

      if (theme.mediaMatches.md) {
        this.overlay.addEventListener('mouseenter', this.closeHandler);
      }
    } else {
      // Remove event listener added on nav opening.
      this.overlay.removeEventListener('click', this.closeHandler);
      this.nav.removeEventListener('keyup', this.closeHandler);

      if (theme.mediaMatches.md) {
        this.overlay.removeEventListener('mouseenter', this.closeHandler);
      }
    }
  }

  /**
   * Closes the main nav menu
   */
  closeMainDisclosure() {
    if (this.mainDisclosure && this.mainDisclosure.classList.contains('is-open')) {
      this.close(this.mainDisclosure, true);
      this.toggleOverlay(false);
      this.childNavOpen = false;
    }
  }

  /**
   * Updates open/opening classes for disclosure element.
   * @param {Element} el - Disclosure element.
   * @param {boolean} [mainMenuOpen=true] - Main menu is open.
   */
  static open(el, mainMenuOpen = true) {
    el.open = true;

    // Cap the max width of grandchildren on desktop their contents don't widen the dropdown
    if (theme.mediaMatches.md && !el.classList.contains('js-mega-nav')) {
      // If the nav menu spills off the right of the screen, shift it to the left
      const dropdownContainer = el.querySelector('.main-nav__child');
      if (dropdownContainer.getBoundingClientRect().right > window.innerWidth) {
        dropdownContainer.classList.add('main-nav__child--offset-right');
      }

      const dropdown = el.querySelector('.child-nav--dropdown');
      if (dropdown) {
        dropdown.querySelectorAll('.main-nav__grandchild').forEach((grandchildElem) => {
          grandchildElem.style.maxWidth = `${dropdown.clientWidth}px`;
        });
      }
    } else if (theme.mediaMatches.md && el.querySelector('.mega-nav--sidebar')) {
      const firstSummaryElem = el.querySelector('.mega-nav--sidebar details[open] summary');
      if (firstSummaryElem) {
        // Open the first sidebar mega menu
        MainMenu.handleSidenavMenuToggle(null, firstSummaryElem);
      }
    }

    // Slight delay required before starting transitions.
    setTimeout(() => {
      el.classList.remove('is-closing');
      el.classList.add('is-open');
    });

    if (mainMenuOpen) {
      removeTrapFocus();
      trapFocus(el);

      if (theme.mediaMatches.md || el.classList.contains('main-menu__disclosure')) {
        document.body.classList.add('overflow-hidden');
      }
    }
  }

  /**
   * Updates close/closing classes of a disclosure element.
   * @param {Element} el - Disclosure element.
   * @param {boolean} [transition=false] - Close action has a CSS transition.
   */
  close(el, transition = true) {
    el.classList.remove('is-open');

    if (transition) {
      el.classList.add('is-closing');
    } else {
      el.classList.remove('is-closing');
      el.open = false;

      removeTrapFocus(this.opener);
      this.opener = null;
    }

    setTimeout(() => {
      const offsetMenu = el.querySelector('.main-nav__child--offset-right');
      if (offsetMenu) offsetMenu.classList.remove('main-nav__child--offset-right');
    }, 200);

    if (theme.mediaMatches.md || el.classList.contains('main-menu__disclosure')) {
      document.body.classList.remove('overflow-hidden');
    }
  }

  /**
   * Decide whether to show a particular mega menu
   * @param {object} evt - Event object
   */
  handleBlockSelect(evt) {
    if (this.nav) {
      const activeDisclosure = this.nav.querySelector('details.is-open');
      if (activeDisclosure) {
        this.close(activeDisclosure, false);
      }
    }

    if (evt.target.matches('.js-mega-nav')) {
      MainMenu.open(evt.target, false);
      this.toggleOverlay(true);
    }
  }

  /**
   * Decide whether to hide a particular mega menu
   * @param {object} evt - Event object
   */
  handleBlockDeselect(evt) {
    if (evt.target.matches('.js-mega-nav')) {
      this.close(evt.target, false);
      this.toggleOverlay(false);
    }
  }
}

customElements.define('main-menu', MainMenu);
