class StoreHeader extends HTMLElement {
  constructor() {
    super();
    this.menu = this.querySelector('.main-menu__content');
    this.searchToggle = this.querySelector('.js-show-search');
    this.searchToggleLeft = this.querySelector('.js-show-search-left');
    this.mobNavToggle = this.querySelector('.main-menu__toggle');
    this.shakeyCartIcon = this.querySelector('.header__icon--cart-shake');
    this.headerGroupSections = document.querySelectorAll('.shopify-section-group-header-group');

    this.stickyInitialised = false;
    this.stickyTransitioning = false;
    this.lastScrollPos = 0;

    this.headerTransitionSpeed = parseFloat(
      getComputedStyle(this).getPropertyValue('--header-transition-speed')
    );

    window.setHeaderHeight();
    this.bindEvents();
    this.init();
  }

  disconnectedCallback() {
    window.removeEventListener('on:debounced-resize', this.resizeHandler);
    if (this.breakpointChangeHandler) {
      window.removeEventListener('on:breakpoint-change', this.breakpointChangeHandler);
    }
  }

  bindEvents() {
    this.resizeHandler = this.resizeHandler || this.updateHeaderHeights.bind(this);
    window.addEventListener('on:debounced-resize', this.resizeHandler);

    if (this.mobNavToggle) {
      this.mobNavToggle.addEventListener('click', window.setHeaderHeight);
      this.mobNavToggle.addEventListener('click', this.setHeaderEnd.bind(this));
    }

    if (this.dataset.isSticky) {
      this.breakpointChangeHandler = this.breakpointChangeHandler || this.init.bind(this);
      window.addEventListener('on:breakpoint-change', this.breakpointChangeHandler);
    }

    if (this.dataset.isSearchMinimised) {
      if (this.searchToggle) {
        this.searchToggle.addEventListener('click', this.handleSearchToggleClick.bind(this));
      }

      if (this.searchToggleLeft) {
        this.searchToggleLeft.addEventListener('click', this.handleSearchToggleClick.bind(this));
      }
    }
  }

  /**
   * Init sticky behaviour of the header
   */
  init() {
    this.updateHeaderHeights();

    if (this.dataset.isSticky) {
      if (theme.mediaMatches.md && !this.stickyInitialised) {
        this.stickyInitialised = true;
        // Animate the menu in/out on scroll up/down
        window.addEventListener('scroll', this.handleScroll.bind(this));
      }

      setTimeout(() => {
        let ccHeader = document.querySelector('.cc-header')
        if (ccHeader) {
          ccHeader.classList.add('cc-header--sticky');
        }
      });

      this.setMenuHeight();
      this.setHeaderEnd();
    }

    // Stop the cart icon from shaking when necessary
    if (this.shakeyCartIcon) {
      let pageCount = theme.storageUtil.get('shake-page-count', false, true);
      pageCount = pageCount ? parseInt(pageCount, 10) + 1 : 1;
      const shakeFrequency = parseInt(this.shakeyCartIcon.dataset.shakeFrequency, 10);
      if (pageCount < shakeFrequency) {
        this.shakeyCartIcon.classList.remove('header__icon--cart-shake');
      } else {
        pageCount = 0;
      }
      theme.storageUtil.set('shake-page-count', pageCount, true);
    }
  }

  /**
   * Toggles visibility of the search bar
   * @param {object} evt - Event object
   */
  handleSearchToggleClick(evt) {
    evt.preventDefault();
    const searchBar = this.querySelector('.js-search-bar');
    if (this.classList.contains('search-is-collapsed')) {
      this.classList.remove('search-is-collapsed');

      // Wait for reveal animation to complete
      setTimeout(() => {
        this.classList.add('search-is-visible');
        const searchInput = searchBar.querySelector('.js-search-input');
        searchInput.focus();
        window.setHeaderHeight();
      }, this.headerTransitionSpeed);
    } else {
      this.classList.remove('search-is-visible');

      setTimeout(() => {
        this.classList.add('search-is-collapsed');
      });

      setTimeout(window.setHeaderHeight, this.headerTransitionSpeed);
    }
  }

  /**
   * Wrapper for calling the two below
   */
  updateHeaderHeights() {
    if (theme.mediaMatches.md) {
      this.setMenuHeight();
      setTimeout(window.setHeaderHeight, this.headerTransitionSpeed);
    } else {
      window.setHeaderHeight();
    }

    // Set a css variable to record where the page content starts
    if (this.headerGroupSections && this.headerGroupSections.length > 0) {
      let headerGroupHeight = 0;
      this.headerGroupSections.forEach((section) => {
        headerGroupHeight += section.getBoundingClientRect().height;
      });

      if (headerGroupHeight > 0) {
        document.documentElement.style.setProperty(
          '--content-start',
          `${headerGroupHeight.toFixed(1)}px`
        );
      }
    }
  }

  /**
   * Set a css variable to the height of the menu links
   */
  setMenuHeight() {
    if (this.menu && this.menu.clientHeight) {
      this.style.setProperty('--menu-height', `${this.menu.clientHeight + 16}px`);
      document.documentElement.style.setProperty('--header-height', `${this.clientHeight}px`);
    }
  }

  /**
   * Handles 'scroll' event on the header
   */
  handleScroll() {
    if (!document.body.classList.contains('fixed') && !this.stickyTransitioning) {
      if (document.documentElement.scrollTop < 200) {
        this.show();
      } else if (this.lastScrollPos < document.documentElement.scrollTop) {
        this.hide();
      } else if (this.lastScrollPos - 5 > document.documentElement.scrollTop) {
        this.show();
      }

      this.lastScrollPos = document.documentElement.scrollTop;
    }
  }

  /**
   * Set a css variable to indicate where the nav ends on the page
   */
  setHeaderEnd() {
    const headerEnd = Number(this.getBoundingClientRect().top + this.clientHeight);
    document.documentElement.style.setProperty('--header-end', `${headerEnd.toFixed(1)}px`);
    document.documentElement.style.setProperty(
      '--header-end-padded',
      `${(headerEnd + (theme.mediaMatches.md ? 56 : 20)).toFixed(1)}px`
    );
  }

  /**
   * Updates hidden class for header element
   */
  show() {
    this.classList.remove('is-out');
    this.stickyTransitioning = true;
    setTimeout(() => {
      this.lastScrollPos = document.documentElement.scrollTop;
      this.stickyTransitioning = false;
      this.handleScroll();
      this.setHeaderEnd();
    }, 300);
  }

  /**
   * Updates hidden class for header element
   */
  hide() {
    if (!this.stickyTransitioning) {
      this.classList.add('is-out');
      this.stickyTransitioning = true;
      setTimeout(() => {
        this.lastScrollPos = document.documentElement.scrollTop;
        this.stickyTransitioning = false;
        this.handleScroll();
        this.setHeaderEnd();
      }, 300);
    }
  }
}

customElements.define('store-header', StoreHeader);
