window.theme = {
      info: {
        name: 'Enterprise',
        version: '1.3.1'
      },
      mediaQueries: {
        sm: '(min-width: 600px)',
        md: '(min-width: 769px)',
        lg: '(min-width: 1024px)',
        xl: '(min-width: 1280px)',
        xxl: '(min-width: 1536px)',
        portrait: '(orientation: portrait)'
      },
      device: {
        hasTouch: window.matchMedia('(any-pointer: coarse)').matches,
        hasHover: window.matchMedia('(hover: hover)').matches
      },
      settings: {
        moneyWithCurrencyFormat: "${{amount}} USD",
        pSearchLimit: 10,
        pSearchLimitScope: 'each',
        pSearchIncludeSkus: false,
        pSearchIncludeTags: true,
        pSearchShowArticles: true,
        pSearchShowCollections: true,
        pSearchShowPages: true,
        pSearchShowProducts: true,
        pSearchShowSuggestions: true,
        sliderItemsPerNav: 'slide',
        
        vibrateOnATC: true,
        compareToggle: "toggle_off",
        compareShowEmptyMetafields: false,
        blendProductImages: false,
        externalLinksNewTab: true,
        afterAtc: "nothing",
        cartType: "page"
      },
      strings: {
        addCartNote: 'Add order note',
        editCartNote: 'Edit order note',
        cartError: 'There was an error while updating your cart. Please try again.',
        cartQtyError: 'You can only add [quantity] of this item to your cart.',
        cartTermsConfirmation: 'You must agree to the terms and conditions before continuing.',
        imageAvailable: 'Image [index] is now available in gallery view',
        lowStock: 'Low stock',
        inStock: 'In stock',
        noStock: 'Sold out',
        noVariant: 'Unavailable',
        onlyXLeft: '[quantity] in stock',
        awaitingSale: 'This product is not for sale yet.',
        shippingCalculator: {
          singleRate: 'There is one shipping rate for this destination:',
          multipleRates: 'There are multiple shipping rates for this destination:',
          noRates: 'We do not ship to this destination.'
        },
        viewDetails: 'View details',
        compare: {
          limit: 'You can only add a maximum of [quantity] products to compare.',
          more: 'Select another product to compare.',
          empty: 'Select at least two products to compare.',
          continue: 'Close to continue.'
        },
        discountCopyFail: 'Could not copy code to clipboard. Your browser may not support this.',
        articleReadTime: '[x] minute read',
        quickNav: {
          button_standard: 'Search',
          show_products_none: 'No products :(',
          button_one: 'Show [quantity] product',
          button_other: 'Show [quantity] products',
        },
      },
      routes: {
        predictiveSearch: '/products/autocomplete'
      }
    };

(() => {
  const { mediaQueries } = theme;
  if (!mediaQueries) return;

  const mqKeys = Object.keys(mediaQueries);
  const mqLists = {};
  theme.mediaMatches = {};

  /**
   * Handles a media query (breakpoint) change.
   */
  const handleMqChange = () => {
    const newMatches = mqKeys.reduce((acc, media) => {
      acc[media] = !!(mqLists[media] && mqLists[media].matches);
      return acc;
    }, {});

    // Update mediaMatches values after breakpoint change.
    Object.keys(newMatches).forEach((key) => {
      theme.mediaMatches[key] = newMatches[key];
    });

    window.dispatchEvent(new CustomEvent('on:breakpoint-change'));
  };

  mqKeys.forEach((mq) => {
    // Create mqList object for each media query.
    mqLists[mq] = window.matchMedia(mediaQueries[mq]);

    // Get initial matches for each query.
    theme.mediaMatches[mq] = mqLists[mq].matches;

    // Add an event listener to each query.
    try {
      mqLists[mq].addEventListener('change', handleMqChange);
    } catch (err1) {
      // Fallback for legacy browsers (Safari < 14).
      mqLists[mq].addListener(handleMqChange);
    }
  });
})();

/**
 * Returns a function that as long as it continues to be invoked, won't be triggered.
 * @param {Function} fn - Callback function.
 * @param {number} [wait=300] - Delay (in milliseconds).
 * @returns {Function}
 */
if (!window.debounce) {
  window.debounce = function(fn, wait = 300) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  }
}

/**
 * Sets a 'viewport-height' custom property on the root element.
 */
if (!window.setViewportHeight) {
  window.setViewportHeight = function() {
    document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
  }
}

/**
 * Sets a 'header-height' custom property on the root element.
 */
if (!window.setHeaderHeight) {
  window.setHeaderHeight = function() {
    const header = document.getElementById('em-section-header');
    if (!header) return;
    let height = header.offsetHeight;

    // Add announcement bar height (if shown).
    const announcement = document.getElementById('em-section-announcement');
    if (announcement) height += announcement.offsetHeight;

    document.documentElement.style.setProperty('--header-height', `${height}px`);
  }
}

/**
 * Sets a 'scrollbar-width' custom property on the root element.
 */
if (!window.setScrollbarWidth) {
  window.setScrollbarWidth = function() {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${window.innerWidth - document.documentElement.clientWidth}px`
    );
  }
}

/**
 * Sets the dimension variables.
 */
function setDimensionVariables() {
  setViewportHeight();
  setHeaderHeight();
  setScrollbarWidth();
}

// Set the dimension variables.
setDimensionVariables();

// Update the dimension variables if viewport resized.
window.addEventListener('resize', window.debounce(setDimensionVariables, 50));


